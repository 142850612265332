.AName2 {
  color: #000080;
  font-weight: bold;
}
.AName {
  font-weight: bold;
  font-size: 18px;
  color: #000080;
}
.AName1 {
  font-weight: bold;
  font-size: 16px;
  color: #000080;
}
.result__card__table {
  margin-top: 20px;
  /* margin-left: 20px; */
}
.muResult1 {
  width: 300px !important;
  text-align: center;
}
.muResult2 {
  width: 125px !important;
  text-align: center;
}
.muResult3 {
  width: 100px !important;
  text-align: center;
}
.muResult4 {
  width: 130px !important;
  text-align: center;
}
.muResult5 {
  width: 125px !important;
  text-align: center;
}
.muResult6 {
  width: 75px !important;

  text-align: center;
}
.muResult7 {
  text-align: center;
  width: 63px !important;
}
.muResult8 {
  text-align: center;
  padding-right: 5px;
  width: 85px !important;
}
