.table-head th{
    font-size: 13px;
    border-bottom: 1px solid black !important;
    border-top: none !important;
    text-align: center;
}
.table-bg{
    background-color: #edeff7;
}
.table-body{
    font-size: 13px;
    
}
td{
    line-height: 2.428571 !important;
    text-align: center;
}
.doubleright{
    font-size:18px;
}