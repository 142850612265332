.rotateIT {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* position: relative; */
  /* text-align: start; */
  height: 150px;
  width: 40px;
}
.rotateIT > p {
  /* position: absolute; */
  width: 100px;
  /* height: 150px; */
  /* text-align: start; */
  transform: rotate(270deg);
  /* right: -150px;
  top: 10px; */
}
.hereLineheighter {
  line-height: 15px !important;
}
.endTotalGlossNet {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-left: 120px;
}
.endTotalGlossNet > div:nth-child(1) {
  margin-right: 10px;
}
.makeITScrollAAble > div {
  max-height: 400px;
}
.myStickyHeader {
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: gainsboro !important;
}
