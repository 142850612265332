.payheadFont{
font-size: 16px !important;
}
.paybodyFont{
font-size: 14px !important;
}
.totalOfPaidSalary{
    display: flex;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 10px 15px;
}
.totalOfPaidSalary>div:nth-child(1)>p:nth-child(2){
    width: 455px !important;
}

.totalOfPaidSalary>div>p{
margin: 0 !important;
font-size: 14px !important;
color: black;
}
.totalOfPaidSalary>div:nth-child(2){
margin-left: 50px !important;

}