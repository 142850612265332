.text_align_start{
    text-align: start;
    /* padding-left: 0px;
     */
   
}
.padding_left{
    padding-left: 20px !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #ffffff !important;
    background-color: #121291 !important;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !important;

}
.svg-icon .checkmenue {
    transition: fill 0.3s ease;
    fill: white !important;
    height: 20px !important;
    width: 20px !important;
    opacity: 1;
}




.Modal_Heading_font{
    font-weight: 600 !important;
    color: white !important;
    
}
.modal_header_style{
display: flex;
justify-content: center !important;
background-color: #121291 ;
}



/* th{
    background-color: #EBEDF3;
} */






.blue_color{
    color: #121291 !important;
}
/* .aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
    background-color:#121291 !important;
} */

.back_color_blue{
    background-color:#05054b !important
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span{
    background-color: white !important;
}
.white_color{
    color: white !important;
}
 .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill]
  {
      opacity: 1;
 }

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: white !important;
    opacity: 1;
}
.checkmenue  g [fill] {
    transition: fill 0.3s ease;
    fill: white !important;
}
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
    color: white !important
}
@media (min-width: 992px){
.brand-dark .brand .btn .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: white !important;
}
}



.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-icon.svg-icon svg g [fill] {
    transition: fill 0.3s ease;
     fill: white !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-icon.svg-icon svg g [fill]{
    transition: fill 0.3s ease;
    fill: white !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow, .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow{
    color:white !important
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-clean:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-clean.focus:not(.btn-text) .svg-icon svg g [fill] {
    transition: fill 0.3s ease  !important ;
     fill: #B5B5C3  !important ;
}


.MuiSvgIcon-colorPrimary {
     color: #121291 !important;
}
.addstudentList td{
text-align: start;
}
.logout_icone{
     width: 30px !important;
    height: 30px !important
    
    
}
.logout_icone svg {
    height: 2.5
rem
 !important;
    width: 2.5
rem
 !important;
}
.svg-icon.svg-icon-md svg.logouticone {
    height: 2.0rem !important;
    width: 2.0rem !important;
}
.logouticone_span{
    padding: 10px;
    color:"white"
}
.svg-icon.logouticone_span svg g [fill] {
    transition: fill 0.3s ease;
    fill: white;
    opacity: 1 !important
}
.svg-icon.logouticone_span svg g:hover [fill]{
    fill: white !important;

}
.logouticone_span{

}
.print_btn_main_div button.button_blue.margintop_none{
    margin-top: 0px !important;
}
.topbar-item .drop_down_button_div:hover{
    color: white !important;
}
.padding_zero{
    padding: 0px!important;
}
.btn_width{
    width: 135px !important;
}
.flex_last{
    display: flex !important;
    justify-content: flex-end !important;
}
.btn_padding_left{
margin-left: 15px !important;
}
.button_blue{
    color: #ffffff !important;
    background-color: #121291 !important;
    border-color: #121291 !important;
    width: 135px !important;
}
.aside-minimize:not(.aside-minimize-hover) .aside {
    width: 94px !important;
}
.viewmodalshow{
    display:flex;
    width:50%;
    align-items:center;
    justify-content:flex-start
}
.viewmodalshow2{
    display:flex;
    width:fit-content;
    align-items:center;
    justify-content:flex-start
}
.switchcheckboxtext{
    font-size: 1.2rem !important;
    padding-top: 5px !important;
}