.mainContainer {
  padding: 20px;
}
.logocontainer {
  width: 100%;
}
.maindetailsContainer {
  height: auto;

  width: 100%;
  border: 2px solid #121291;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.leftsalarySlip {
  margin-top: 30px;
  padding: 20px;
  width: 60%;
}
.leftlipcard {
  display: flex;
  padding-bottom: 5px;
}
.leftlipcard > h5 {
  font-weight: 600;
}
.rightSlip {
  padding: 20px;
  height: auto;
  border: 2px dashed black;
  width: 480px;
  border-radius: 30px;
}
.rightSlipconta {
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: end;
  /* width: 78%; */
}
.paydetailshe {
  text-align: center;
  padding-right: 140px;
  font-weight: 600;
}
.rightslipcard {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.overdevider {
  margin-top: 20px;
}
.accountant {
  position: relative;
  top: 30%;
  left: 10%;
  width: 200px;
}
.accountant > div {
  width: 200px;
  height: 1px;
  border: 1px solid black;
}
.accountant > h5 {
  font-weight: 600;
  text-align: center;
  padding-top: 1px;
}
.thisisforpagebreak {
  page-break-after: always !important;
}
