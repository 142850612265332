
 .secondpicler{
    padding-left: 20px;
}
@media (max-width:600px){
    .datepickerContaine{
        display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .secondpicler{
        padding-left: 0px!important;
    }
}
.fontchagerledger{
    font-size: 17px !important;
}
.fontchagerledger2{
    font-size: 16px !important;
    color: black !important;
    border-bottom: 1px solid black;
}
.ffonmt{
    font-size: 18px;
    font-weight: bold;
}
.MydividerContainer{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
}
.MydividerContainer>div{
    width: 67px;
    height: 4px;
    background: black;
}