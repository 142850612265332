.totalOfPaidSalary2{
    display: flex;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 10px 15px;
}
.totalOfPaidSalary2>div:nth-child(1)>p:nth-child(2){
    width: 365px !important;
}

.totalOfPaidSalary2>div>p{
margin: 0 !important;
font-size: 14px !important;
color: black;
}
.totalOfPaidSalary2>div:nth-child(2){
margin-left: 50px !important;

}
.nextmoreFont{
    font-size: 18px !important;
}