.print_modal_linebreak {
    width           : 800px;
    margin-left     : 2%;
    height          : 2px;
    background-color: blue;
   
  }
  .print_modal_data_table_most_main_div{
      display: flex;
      justify-content: center;
      width: 700px;
      height: 300px;
  }
  .print_modal_data_table_main_div{
    width: 92%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.print_modal_data_tabl_div{
    height: 100%;
}
.print_modal_data_comment_div{
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    padding-top: 40px;

}
.print_modal_data_comment_signature_div{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.print_modal_data_comment_congrat_div{
    display: flex;
    width: 100%;
}
.print_modal_congrate_heading{
    border: 1px solid black;
    border-radius: 11px;
    padding: 5px;
}
.print_modal_date_div{
    display: flex;
    justify-content: flex-end;
    margin-right: 15%;
}
@media only screen and (max-width: 991px) {
    .print_modal_data_tabl_div{
        height: fit-content;
    }
    .print_modal_data_comment_div{
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        padding-top: 20px;
      

    
    }
 
    .print_modal_data_comment_congrat_div{
      
        justify-content: center;
      
    }
}



 