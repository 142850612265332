.mainDiv{
  display: flex;
  justify-content: space-around;
  /* border: 1px solid black; */
  transform: rotate(270deg);
  height: 1800px;
  margin-right: 370px;
  margin-top: 190px;
  width: 2200px !important;
}
.box{
    height: 300px;
    width: 500px;
    /* border: 1px solid black !important; */
    position: relative;
    top: 140px;
    right: 100px;
}
.top3lines{
    text-align: center;
}
.top3lines>b{
 font-size: 2rem;
}
.middlecontent{
    text-align: left;
    margin-top: 5px;
    height: auto;
}
.middlecontent>span>b{
    font-size: 2rem;
}
.middlecontent>span>p{
    font-size: 2rem;
}
.secondColoumn{
    position: absolute;
    left: 190px;
}
.endconheading{
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
}
.endconheading>div>b{
    font-size: 2rem;
}
.endcontentMain{
    display: flex;
    justify-content: space-around;
    /* justify-content: center; */
    margin-top: 20px; 
}
.endcontentMain>div{
   text-align: center;
}
.endcontentMain>div>p{
  font-size: 2rem;
 }
 .bottomContent{
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: -310%;
 }
 .bottomContent>div>b{
    font-size: 2.5rem;
    margin-left: 10px;
 }
 .bottomContent>div>p{
    font-size: 2rem;
    padding: 15px;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 100px;
 }
