.tableboot {
  overflow-y: scroll !important;
}

.duefeeetd > td {
  font-size: 15px;
}
.hadhybhai {
  page-break-before: avoid; /* or avoid-always */
}

.duefeesec {
  width: 300px !important;
}

/* .checkbox > span {
  background-color: #9bb0f0 !important;
  border: 1px solid #121291 !important;
} */
