@media (max-width:600px){
    .dailytransmain{
       overflow-x: scroll;
    }
}
.headerDailytrans{
    display: flex;
    justify-content: space-between;
}
.border{
    border: 1px solid black;
}