.demandName{
    /* width: 300px !important; */
}
.demandremarks{
    min-width: 200px !important;

}
.headtr>td{
color: black;
}
.demandLeaving{
    min-width: 80px;
}
.marginLeftTolegal{
    /* margin-left: 130px !important; */
}