.rotateIT {
    /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
}
.rotateIT>p{
  position: absolute;
  width: 80px;
    transform: rotate(270deg); 
    right: -30px;
}
.hereLineheighter{
  line-height: 15px !important;
}
.endTotalGlossNet{
 display: flex;
 justify-content: end;
 margin-top: 20px;
 margin-right: 130px;
}
.endTotalGlossNet>div:nth-child(1){
  margin-right: 170px;
}
.makeITScrollAAble>div{
 max-height: 400px;
}
.myStickyHeader{
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: gainsboro !important;
}