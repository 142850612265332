.card-1{
    display: flex;
    align-items: center;
    flex-direction: row;

 
}

.ico{
    height: 100px;
    width: 100px;
}