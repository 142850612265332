.linner{
    height: 1px;
    border: 2px solid #121291;
    width: 120px;
    border-radius: 10px;
    margin-top: 5px;
}
.linnerContaioner{
    display: flex;
    justify-content: center;
    width: 100%;
}
.depracateheading{
    font-family  : 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}