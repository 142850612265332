.tablemain {
  height: 430px;
  overflow: scroll;
  border: 1px solid #777777;
}
.table {
  border-spacing: 0;
}
.tablehead {
}
.tableheadtr {
}
.tableHEadNAme {
  borders: 1px solid #bbbb;
  padding: 5px;
  width: 80px;
  position: sticky;
  min-width: 80px;
  top: 0;
  background: #121291 !important;
  color: white;
  font-weight: normal;
}
.tbodytr {
}
.tbodytr:hover {
}
.tbodytd {
  border: 1px solid #bbbb;
  padding: 5px;
  width: 80px;
  min-width: 80px;
}
.tbodytr:nth-child(odd) {
}
.tbodytr:nth-child(odd):hover {
}
.tableHEadNAme:nth-child(1) {
}
.fixed {
  position: sticky;
  left: 0;
  box-shadow: 5px 0 3px -2px #ccc !important;
  color: black;
  background-color: rgb(211, 206, 206) !important;
}
.fixed2 {
  position: sticky;
  left: 72px;
  box-shadow: 5px 0 3px -2px #ccc !important;
  color: black;
  background-color: rgb(211, 206, 206) !important;
}
.fixed3 {
  position: sticky;
  left: 150px;
  box-shadow: 5px 0 3px -2px #ccc !important;
  color: black;
  background-color: rgb(211, 206, 206) !important;
}
.adjusting {
  position: sticky;
  left: 245px;
  color: black;
  background-color: rgb(211, 206, 206) !important;
}
.tablehedfixed1 {
  z-index: 1;
  position: sticky;
  left: 0;
}
.tablehedfixed2 {
  z-index: 1;
  position: sticky;
  left: 72px;
}
.tablehedfixed3 {
  z-index: 1;
  position: sticky;
  left: 150px;
}
.tablehedfixed4 {
  z-index: 1;
  position: sticky;
  left: 245px;
}
