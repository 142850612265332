.managinHeight{
    height: 35px !important;
    margin: 0px !important;

}
/* .managingtrHeight{
    height: 1px !important;
} */
.checkDobheight{
    width: 100px !important;
}
.handleAddressAligment{
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.checkAddress{
    width: 300px;
    line-height: 15px !important;
}
.checklistlinehighter{
    line-height: 15px !important;
}
