.logo-icons {
  padding: 10px;
  display: flex;
}

.my-print {
  display: flex;
  flex-direction: column;

  /* align-items: center;
    justify-content: center;  */
  /* height: 2480px;
    width: 3508px; */
}

.my__print__1 {
  width: 370px;
  padding: 0px !important;
  margin-left: 230px;
  /* margin: -82px !important; */
  /* margin: 0px;
    padding: 0px; */
  /* margin-top: 50px; */
  position: relative;
  top: 20px;
  -ms-transform: rotate(270deg);
  /* border: 1px solid black; */
  transform: rotate(270deg);
  /* margin-top: 10px; */
}

.my__print__2 {
  width: 370px;
  margin: 0px;
  padding: 0px;
  /* margin-top: 50px; */
  position: relative;
  top: 40px;
  margin-left: 230px;
  /* border: 1px solid black; */
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  /* margin-top: 10px; */
}

.my__print__3 {
  width: 370px;
  margin: 0px;
  padding: 0px;
  position: relative;
  top: 80px;
  margin-left: 230px;
  -ms-transform: rotate(270deg);
  /* border: 1px solid black; */
  /* margin-top: 10px; */
  transform: rotate(270deg);
}

.text-form {
  display: flex;
}

.list-left li {
  list-style: none;
  /* font-size: 18px; */
}

.right-content li {
  list-style: none;
}

.print-copy {
  height: 200px;
  position: relative;
  top: 30px;
  left: 30px;
  /* margin-: 10px; */
  /* border: 1px solid black; */
}

.total__bottom {
  position: relative;
  top: 300px;
}

.total__bottom_2 {
  position: relative;
  top: 320px;
}
