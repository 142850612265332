.mainPrintDiv{
 height: 100vh;
 width: 100%;
 /* box-sizing: border-box; */
 /* clear: both; */
 /* transform: rotate(270deg); */
 /* border: 1px solid black; */
 position: relative;
/* transform: rotate(270deg); */

 /* margin: 0;
 padding: 0; */
}
.secondmain{
    display: flex;
    /* justify-content: space-between !important; */
    /* border: 1px solid black; */

    transform: rotate(270deg);
    position: relative;
    top: 15%;
    left: -7%;
    height: auto;
    width: auto;

}
.myprintBox1{

    /* border: 1px solid black; */
    width: 400px !important;
    height: 700px !important;
    position: absolute;
    /* padding-top: 25px; */
    left: 60%;
    /* right: 50%; */


    /* transform: translateY(-700px) rotate(270deg); */
    /* transform: rotate(270deg); */
  /* position: absolute; */
  /* left: 29%;  */
  /* left: 50%; */
  /* top: 20px; */
}
.myprintBox2{
    width: 400px !important;
    height: 700px !important;
    /* border: 1px solid red; */

    /* margin-right: 200px; */
    /* transform: translateY(-700px) rotate(270deg); */
    /* padding-top: 25px; */

    /* transform: rotate(270deg); */
    /* position: absolute; */
    /* top: 20%; */
    /* left: 29%;  */
    /* transform-origin: 0% 0%; */
    position: absolute;
    /* right: 10%; */
    left: 14%;


  }
.myprintBox3{
    /* border: 1px solid blue; */

    width: 400px !important;
    height: 700px !important;
    position: relative;
    /* padding-top: 25px; */
     right: 30%;
    /* margin-right: 200px; */
    /* position: absolute; */
     /* right: 50%; */
    /* transform: translateY(-700px) rotate(270deg); */

    /* transform: rotate(270deg); */
    /* position: absolute; */
    /* top: 30%; */
  /* transform-origin: 0% 0%; */

    /* top: 35%; */

    /* left: 29%;  */


  


}
.mytextfont{
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
}
.overtd{
    padding-top: 0 !important;
    text-align: left !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
    color: red !important;
}
.overtr{
    padding: 0 !important;
    margin: 0 !important;
}
.printsecontpart{
    /* padding-left: 30px !important; */
    width: 20px;
}
.printsecondpartcontainer{
    padding: 0;
    margin: 0;
  display: flex;
}
.printcontainerheight{
    height: 25px;
}

.printseconcontent{
    text-align: start;
    /* padding-left: 40px; */
    width: auto;
 
}

.endconheading2{
    display: flex;
    justify-content: space-evenly;
    margin-top: 70px;
}
.endconheading2>div>b{
    font-size: 1.2rem;
}
.endContent2{

}
/* .endconheading2{

} */
.endcontentMain2{
    display: flex;
    justify-content: space-evenly;
    /* justify-content: center; */
    margin-top: 20px; 
}
.endcontentMain2>div{
   text-align: center;
}
.endcontentMain2>div>p{
  font-size: 1.2rem;
 }

.my__total__bottom2{
    position: relative;
    /* top: 240px; */
}
.my__total__bottomfor1{
    position: absolute;
    top: 20%;
    transform: rotate(270deg);
    left: 85%;

}
.my__total__bottomfor2{
    position: absolute;
    top: 55%;
    transform: rotate(270deg);
    left: 85%;

}
.my__total__bottomfor3{
    position: absolute;
    top: 85%;
    left: 85%;
    transform: rotate(270deg);

}
.my__total__bottom4{
    position: fixed;
    top: 890px;
    /* left: 40px; */
    margin-left: 30px;
}
.totalFontSizer{
    /* font-size: 3rem; */
    font-size: 20px !important;
}
.endcontentMain2>div>p{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 18px !important;

}
.endcontentMain2>div{
    height: 5px !important;
}