.permsonScoller {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f2f2f2; /* For Firefox */
  
    /* Webkit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 10px; /* Width of the scrollbar */
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the thumb */
      border-radius: 5px; /* Rounded corners for the thumb */
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f2f2f2; /* Color of the track */
    }
  }
  